import { onUpdated, onMounted, onUnmounted, watch } from 'vue';
import { Ref } from '@vue/reactivity/dist/reactivity';
import { FormInputUIResult } from '@/lib/formFactory/autocomplete/ui';
import { FormAutocompleteHandlerResult } from '@/lib/formFactory/autocomplete/handler';

export interface FormAutocompleteClickOutsideParams {
  emit: (eventName: string, eventData: unknown) => void;
  ref: Ref<null | HTMLElement>;
  ui: FormInputUIResult;
  handler: FormAutocompleteHandlerResult;
  modelValue: Ref<{ id: string | number; value: string | number }>;
  reactModel: boolean;
}

export default function formAutocompleteClickOutside(params: FormAutocompleteClickOutsideParams): void {
  const leave = () => {
    const value = params.handler.value.value ? params.modelValue.value : params.handler.value;

    if (!value.value) {
      const result = {
        id: null,
        value: '',
      };

      params.emit('update:modelValue', result);
      params.emit('select', result);
    }

    params.handler.value.value = value.value;

    params.ui.setViewList(false);
  };

  const documentClick = (e: Event) => {
    const el = params.ref.value;
    const target = e.target as Node;

    if (params.ui.viewList.value && target) {
      if (el && el !== target && !el.contains(target)) {
        leave();
      }
    }
  };

  onUpdated(() => {
    document.body.addEventListener('click', documentClick);
  });

  onMounted(() => {
    document.body.addEventListener('click', documentClick);

    const el = params.ref.value;

    if (!el) {
      return;
    }

    el.addEventListener('keyup', (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        leave();

        el.blur();
      }
      if (e.key === 'Enter') {
        e.preventDefault();

        return false;
      }
    });
  }),
    onUnmounted(() => {
      document.body.addEventListener('click', documentClick);
    });

  watch(params.modelValue, () => {
    if (params.reactModel) {
      params.handler.value.value = params.modelValue.value.value;
    }
  });
}
