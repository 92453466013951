/**
 * Shop statistics.
 *
 */

import { Module, Action, getModule, Mutation } from 'vuex-module-decorators';
import EntityBaseModule from '@/store/entity';
import store from '@/store';
import { ShopEmployeesEntityModel } from '@/interfaces/models/shop.interface';
import EntityModel from './entityModel';
import ResponseHandlerModule from '@/store/modules/responseHandler';
import ShopsEmployeesModule from './index';

import { saveFavoriteEmployee, getFavoriteEmployeeById } from '@/api/shop';

export const MODULE_NAME = 'shopsEmployeesEntity';
import { TableInterface, TableRow } from '@/lib/layouts/page/table.interface';
import { strings } from '@/lib/stringConst';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class ShopsEmployeesEntityModule extends EntityBaseModule {
  model: ShopEmployeesEntityModel;

  constructor(module: ShopsEmployeesEntityModule) {
    super(module);

    const entityModel = new EntityModel();
    this.model = entityModel.model;
  }

  @Mutation
  SET_COMMENT(comment: string) {
    this.model.comment = comment;
  }

  @Mutation
  UPDATE_EMPLOYEE(value: { id: string; value: string }) {
    this.model.employee = value;
  }

  @Mutation
  SET_IS_FAVORITE(value: { id: string; value: string }) {
    this.model.isFavourite = value;
  }

  @Action({ rawError: true })
  updateEmployee(params: { id: string; value: string }) {
    this.context.commit('UPDATE_EMPLOYEE', params);
  }

  @Action({ rawError: true })
  updateIsFavourite(value: { value: string }) {
    this.context.commit('SET_IS_FAVORITE', { id: value.value, value: value.value });
  }

  @Action({ rawError: true })
  setHatedWarning(shopId: string) {
    ShopsEmployeesModule.setHatedWarning(shopId);
  }

  @Action({ rawError: true })
  editFavorite(params: { shopId: string; employeeId: string }) {
    this.getById(params).then(() => {
      ShopsEmployeesModule.showModal('edit');
    });
  }

  @Action({ rawError: true })
  async getById(params: { shopId: string; employeeId: string }) {
    try {
      const result = await getFavoriteEmployeeById(params.shopId, params.employeeId);

      result['employee'] = Object.values((ShopsEmployeesModule.pageSettings.table as TableInterface).rows).find(
        (row: TableRow): boolean => parseInt(row.id as string) === parseInt(params.employeeId)
      );

      this.setInfoById(result);
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.errors.fields.status, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  setInfoById(info: { status: string; employee: { comment: ''; fullName: string; id: string; status: string } }) {
    this.updateIsFavourite({ value: info.status });
    this.updateComment(info.employee.comment);

    const employee = {
      id: info.employee.id,
      name: info.employee.fullName,
      actions: {},
    };

    this.context.commit('UPDATE_EMPLOYEE', employee);
  }

  @Action({ rawError: true })
  async saveUpdate(shopId: string) {
    try {
      const data = await this.prepareFormData();
      const result = await saveFavoriteEmployee(shopId, this.model.employee.id as string, data);

      if (!result.message) {
        ResponseHandlerModule.showNotify({ message: 'Изменения сохранены', type: 'ok' });
      } else {
        ResponseHandlerModule.showNotify({ message: result.message, type: 'fail' });
      }
      await ShopsEmployeesModule.initListById(shopId);
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.message ?? strings.UNKNOWN_ERROR, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  async prepareFormData() {
    try {
      return {
        status: this.model.isFavourite.value,
        comment: this.model.comment ?? '',
      };
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.message ?? strings.UNKNOWN_ERROR, type: 'fail' });

      return {};
    }
  }

  @Action({ rawError: true })
  resetModel() {
    this.context.commit('UPDATE_EMPLOYEE', {});
    this.context.commit('SET_IS_FAVORITE', {});
    this.context.commit('SET_COMMENT', '');
    this.context.commit('UPDATE_EMPLOYEE', { id: null, value: '' });

    this.context.commit('RESET_ERROR_BY_FIELD', 'employee');
  }
}
export default getModule(ShopsEmployeesEntityModule);
