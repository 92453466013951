import { ShopEmployeesEntityModel } from '@/interfaces/models/shop.interface';

export default class EntityModel {
  model: ShopEmployeesEntityModel;

  constructor() {
    this.model = {
      employee: { id: null, value: '' },
      isFavourite: {},
      comment: '',
    };
  }
}
