
import { Component, Vue } from '@/lib/decorator';
import AddIcon from '@/components/icons/Add.vue';

@Component({
  components: { AddIcon },
})
export default class AppHeaderBtnAdd extends Vue {
  toAdd() {
    this.$emit('click');
  }
}
