import { ref } from 'vue';
import { Ref } from '@vue/reactivity/dist/reactivity';

export interface FormInputUIParams {
  modelValue: Ref<{ id: string | number; value: string | number }>;
  emit: (attr1: string, attr2: unknown) => void;
}

export interface FormInputUIResult {
  isLabelActive: Ref<boolean>;
  addClassIn: () => void;
  removeClassIn: () => void;
  viewList: Ref<boolean>;
  setViewList: (state: boolean) => void;
  getSelectItem: (value: number) => boolean;
}

export default function formInputUI(params: FormInputUIParams): FormInputUIResult {
  const viewList = ref(false);
  const isLabelActive = ref(false);

  const setViewList = (state: boolean) => {
    viewList.value = state;
  };

  const addClassIn = () => {
    isLabelActive.value = true;

    params.emit('search', params.modelValue.value.value);

    setViewList(true);
  };

  const removeClassIn = () => {
    isLabelActive.value = false;
  };

  const getSelectItem = (value: number) => {
    return params.modelValue.value.id === value;
  };

  return {
    isLabelActive,

    addClassIn,
    removeClassIn,

    viewList,
    setViewList,

    getSelectItem,
  };
}
