import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, createElementVNode as _createElementVNode, renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers, withKeys as _withKeys } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "cp-search-drop__icon"
}
const _hoisted_2 = ["id", "placeholder", "disabled", "name", "autofocus", "readonly", "data-name", "data-error", "data-error-messages"]
const _hoisted_3 = ["value", "id", "type", "placeholder", "disabled", "name", "autofocus", "readonly", "data-name", "data-error", "data-error-messages"]
const _hoisted_4 = { key: 0 }
const _hoisted_5 = {
  key: 0,
  class: "mr-autocomplete-items cp-input-radius-50 cp-width__min--320"
}
const _hoisted_6 = {
  key: 0,
  style: {"min-height":"70px"}
}
const _hoisted_7 = {
  key: 1,
  class: "mr-autocomplete-item__wrapper mr-autocomplete-item__wrapper_disabled"
}
const _hoisted_8 = {
  key: 2,
  tabindex: "-1",
  role: "listbox"
}
const _hoisted_9 = ["onClick", "onKeyup"]
const _hoisted_10 = {
  key: 0,
  class: "mr-autocomplete-item__text"
}
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = ["innerHTML"]
const _hoisted_14 = ["innerHTML"]
const _hoisted_15 = {
  key: 1,
  class: "cp-hint-message"
}
const _hoisted_16 = {
  key: 2,
  class: "cp-error-message"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_font = _resolveComponent("icon-font")!
  const _component_loading = _resolveComponent("loading")!

  return (_openBlock(), _createElementBlock("div", {
    ref: "autocomplete",
    class: _normalizeClass(["mr-search-drop-block__wrapper", { 'cp-flat': _ctx.icon }])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["cp-input-wrapper", { 'mr-search-drop__wrapper': _ctx.icon }])
    }, [
      _createElementVNode("label", {
        class: _normalizeClass(["mr-search-drop__wrapper", { 'cp-error': !_ctx.icon && (_ctx.errorResult.result.value || _ctx.errorResult.messages.value.length) }])
      }, [
        (_ctx.label)
          ? (_openBlock(), _createElementBlock("span", {
              key: 0,
              class: _normalizeClass(["mr-search-drop__label", {
            'mr-search-drop__label-active':
              _ctx.ui.isLabelActive.value || _ctx.handler.value.value || (_ctx.modelValue && _ctx.modelValue.length) || _ctx.labelActive,
          }])
            }, _toDisplayString(!_ctx.placeholder || (_ctx.placeholder && _ctx.handler.value.value) ? _ctx.label : ''), 3))
          : _createCommentVNode("", true),
        _createElementVNode("span", {
          class: _normalizeClass(["mr-input-fake__wrapper", { 'cp-input-radius-50': !_ctx.icon, disabled: _ctx.disabled }])
        }, [
          (_ctx.icon)
            ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
                _createVNode(_component_icon_font, {
                  size: _ctx.iconSize,
                  icon: _ctx.icon
                }, null, 8, ["size", "icon"])
              ]))
            : _createCommentVNode("", true),
          (_ctx.isTextArea)
            ? _withDirectives((_openBlock(), _createElementBlock("textarea", {
                key: 1,
                ref: "root",
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.handler.value.value) = $event)),
                id: _ctx.id,
                placeholder: _ctx.placeholder,
                disabled: _ctx.disabled,
                name: _ctx.name,
                autofocus: _ctx.autofocus,
                readonly: _ctx.readonly,
                "data-name": _ctx.nameResult.nameInput.value,
                "data-error": _ctx.errorResult.result.value,
                "data-error-messages": _ctx.errorResult.messages.value,
                rows: '1',
                onFocusin: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.ui.addClassIn && _ctx.ui.addClassIn(...args))),
                onFocus: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.ui.addClassIn && _ctx.ui.addClassIn(...args))),
                onFocusout: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.ui.removeClassIn && _ctx.ui.removeClassIn(...args))),
                onInput: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.handler.update && _ctx.handler.update(...args))),
                onPaste: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.handler.paste && _ctx.handler.paste(...args)))
              }, null, 40, _hoisted_2)), [
                [_vModelText, _ctx.handler.value.value]
              ])
            : _createCommentVNode("", true),
          (!_ctx.isTextArea)
            ? (_openBlock(), _createElementBlock("input", {
                key: 2,
                ref: "root",
                value: _ctx.handler.value.value,
                id: _ctx.id,
                type: _ctx.types.type.value,
                placeholder: _ctx.placeholder,
                disabled: _ctx.disabled,
                name: _ctx.name,
                autofocus: _ctx.autofocus,
                readonly: _ctx.readonly,
                autocomplete: "off",
                "data-name": _ctx.nameResult.nameInput.value,
                "data-error": _ctx.errorResult.result.value,
                "data-error-messages": _ctx.errorResult.messages.value,
                onFocusin: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.ui.addClassIn && _ctx.ui.addClassIn(...args))),
                onFocus: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.ui.addClassIn && _ctx.ui.addClassIn(...args))),
                onFocusout: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.ui.removeClassIn && _ctx.ui.removeClassIn(...args))),
                onInput: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.handler.update && _ctx.handler.update(...args))),
                onPaste: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_ctx.handler.paste && _ctx.handler.paste(...args)))
              }, null, 40, _hoisted_3))
            : _createCommentVNode("", true)
        ], 2)
      ], 2),
      _renderSlot(_ctx.$slots, "append_out")
    ], 2),
    (!_ctx.hideEmptyValue || (_ctx.hideEmptyValue && _ctx.handler.value.value.length && !_ctx.hideList))
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (_ctx.ui.viewList.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                (_ctx.loading)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      _createVNode(_component_loading, { class: "page-loader-overlay-center" })
                    ]))
                  : (!_ctx.items.length && _ctx.handler.value.value.length)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_7, " Нет результатов "))
                    : _createCommentVNode("", true),
                (!_ctx.loading && _ctx.items.length)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: item.id,
                          class: _normalizeClass(["mr-autocomplete-item__wrapper", { 'mr-autocomplete-item__wrapper_active': _ctx.ui.getSelectItem(item.id) }]),
                          onClick: ($event: any) => (_ctx.list.select(item)),
                          onKeyup: _withKeys(($event: any) => (_ctx.list.select(item)), ["enter"]),
                          tabindex: "0",
                          "aria-selected": "false",
                          role: "option"
                        }, [
                          (!item.modifyValue)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_10, [
                                (item.html)
                                  ? (_openBlock(), _createElementBlock("span", {
                                      key: 0,
                                      innerHTML: item.html
                                    }, null, 8, _hoisted_11))
                                  : (_openBlock(), _createElementBlock("span", {
                                      key: 1,
                                      innerHTML: _ctx.keyValue ? item[_ctx.keyValue] : item.value
                                    }, null, 8, _hoisted_12)),
                                (item.linkHtml && item.linkHtml.length)
                                  ? (_openBlock(), _createElementBlock("span", {
                                      key: 2,
                                      onClick: _cache[11] || (_cache[11] = _withModifiers(() => {}, ["stop"])),
                                      innerHTML: item.linkHtml
                                    }, null, 8, _hoisted_13))
                                  : _createCommentVNode("", true)
                              ]))
                            : (_openBlock(), _createElementBlock("span", {
                                key: 1,
                                class: "mr-autocomplete-item__text",
                                innerHTML: item.modifyValue
                              }, null, 8, _hoisted_14))
                        ], 42, _hoisted_9))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.hint && !_ctx.errorResult.result.value && !_ctx.errorResult.messages.value.length)
      ? (_openBlock(), _createElementBlock("span", _hoisted_15, _toDisplayString(_ctx.hint), 1))
      : _createCommentVNode("", true),
    (_ctx.errorResult.messages.value)
      ? (_openBlock(), _createElementBlock("span", _hoisted_16, _toDisplayString(_ctx.errorResult.messages.value), 1))
      : _createCommentVNode("", true)
  ], 2))
}