
import { defineComponent } from 'vue';

import TabsNav from '@/components/tabs/TabsNavIcons.vue';
import TitleReturn from '@/components/TitleReturn.vue';
import StarIcon from '@/components/icons/Star.vue';

import { TabsNavInterface } from '@/interfaces/tabs.interface';
import ShopEntityModule from '@/store/shops/entity';

export default defineComponent({
  components: {
    TabsNav,
    TitleReturn,
    StarIcon,
  },
  emits: ['toAdd'],
  props: {
    currentTab: {
      type: String,
      required: true,
    },
    addTooltip: {
      type: String,
      default: '',
    },
  },
  computed: {
    shopId(): string {
      return this.$route.params.shopId as string;
    },
    isTest(): boolean {
      return ShopEntityModule.model.isTest;
    },
    rating(): string {
      return ShopEntityModule.model.rating;
    },
    titlePage(): string {
      return ShopEntityModule.titleEdit;
    },
    tabsNav(): TabsNavInterface {
      return ShopEntityModule.tabsNav;
    },
  },
  methods: {
    returnBefore(): void {
      this.$router.push({ name: 'shops' });
    },
  },
});
