/**
 * Shop statistics.
 *
 */

import { Module, Action, getModule, Mutation } from 'vuex-module-decorators';
import PageBaseModule from '@/store/page';
import store from '@/store';

import PageEntity from '@/lib/layouts/page/pageEntity';
import { getListById, deleteEmployeeFromFavorite } from '@/api/shop';
import { modalParams, ModalParamsInterface } from './modalParams';
import ResponseHandlerModule from '@/store/modules/responseHandler';

import ShopsEmployeesEntityModule from './entity';
import ModalsModule from '@/store/modals';
import { TableApiInterface } from '@/lib/layouts/page/table.interface';

import { TableInterface, TableRow } from '@/lib/layouts/page/table.interface';
import { PageSort } from '@/lib/layouts/page/page.interface';
import { UITable } from '@/lib/util/tableUtils';

export const MODULE_NAME = 'shopEmplyees';
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class ShopsEmployeesModule extends PageBaseModule {
  modalParams: ModalParamsInterface = modalParams;
  dictionary: { key: string; name: string; enum: number; icon: string }[] = [
    { key: 'is_favourite', name: 'Любимый', enum: 2, icon: 'smile-love' },
    { key: 'is_liked', name: 'Нравится', enum: 1, icon: 'smile-like' },
    { key: 'is_hated', name: 'Нелюбимый', enum: 0, icon: 'smile-hated' },
  ];
  shopId = '';

  constructor(module: ShopsEmployeesModule) {
    super(module);

    const page = new PageEntity();
    page.setTitle('');
    page.values.actionPagination = 'shopEmplyees/updatePage';
    this.pageSettings = page.values;
    this.pageSettings.actionsHandler = {
      additional: {
        status: 'customers/shops/employees/getById',
      },
    };
    this.pageSettings.sort = {
      0: {
        key: 'fullName',
        sort: 'ASC',
      },
    };
  }

  @Mutation
  SET_SORT(sorts: PageSort) {
    this.pageSettings.sort = sorts;
    window.localStorage.shopsEmployeesSort = JSON.stringify(sorts);
  }

  @Mutation
  TOGGLE_MODAL(payload: { type: string; bool: boolean }) {
    this.modalParams[payload.type].isShow = payload.bool;
  }

  @Mutation
  SET_DELETE_ID(id: string) {
    this.modalParams.delete.id = id;
  }

  @Mutation
  SET_DELETE_SHOP_ID(id: string) {
    this.modalParams.delete.shopId = id;
  }

  @Mutation
  SET_DELETE_TEXT(text: string) {
    this.modalParams.delete.text = text;
  }

  @Mutation
  SET_SHOP_ID(shopId: string) {
    this.shopId = shopId;
  }

  @Action({ rawError: true })
  async initListById(shopId: string) {
    this.context.commit('SET_SHOP_ID', shopId);
    this.pageSettings.pageAmountItems = await this.context.dispatch('getPageAmountStorageValue', MODULE_NAME);

    await this.getListById(shopId);
  }

  @Action({ rawError: true })
  async getListById(shopId: string) {
    try {
      const sort = await this.getSortForRequest();
      const itemsQuery = await this.context.dispatch('getItemsQuery', MODULE_NAME);
      const result = await getListById(shopId, this.pageSettings.pageCurrent, itemsQuery, sort);
      this.setList(result.table);
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.errors.fields, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  setList(table: TableApiInterface) {
    const uiTable = new UITable(table as any);
    uiTable.setProperties('status', { id: 'status', width: '28%', name: 'Статус в объекте' });
    const sorts = Object.values(this.pageSettings.sort);
    const tableInfo = uiTable.setProperty('_actions', 'align', 'right').setSortableValues(sorts).getTable() as any;

    this.context.commit('SET_TABLE', tableInfo);
  }

  @Action({ rawError: true })
  async updatePageAmountItems(number: string) {
    await this.context.commit('SET_PAGE', 1);
    await this.context.dispatch('updatePaginationSettings', { moduleName: MODULE_NAME, amountItems: number });
    await this.initListById(this.shopId);
  }

  @Action({ rawError: true })
  async sort(params: { field: string; sort: string }) {
    const sortProcessed = await this.context.dispatch('sortProcessed', params);
    await this.context.commit('SET_SORT', sortProcessed);
    await this.initListById(this.shopId);
  }

  @Action({ rawError: true })
  showModal(type: string) {
    this.context.commit('TOGGLE_MODAL', { type: type, bool: true });
  }

  @Action({ rawError: true })
  hideModal(type: string) {
    ShopsEmployeesEntityModule.resetModel();
    this.context.commit('TOGGLE_MODAL', { type: type, bool: false });
  }

  @Action({ rawError: true })
  setHatedWarning(shopId: string) {
    this.context.commit('SET_SHOP_ID', shopId);
    ModalsModule.updateParams(this.modalParams.hated);
    ModalsModule.openModalByType('warning');
  }

  @Action({ rawError: true })
  async saveUpdate() {
    await ShopsEmployeesEntityModule.saveUpdate(this.shopId);
    this.hideModal('add');
    this.hideModal('edit');
  }

  @Action({ rawError: true })
  deleteFromFavorite(params: { shopId: string; employeeId: string }) {
    const employee = Object.values((this.pageSettings.table as TableInterface).rows).filter(
      (item: TableRow) => item.id === parseInt(params.employeeId)
    )[0];

    this.context.commit('SET_DELETE_ID', params.employeeId);
    this.context.commit('SET_DELETE_SHOP_ID', params.shopId);
    this.context.commit('SET_DELETE_TEXT', `Ты действительно хочешь удалить ${employee.fullName} из любимого/нелюбимого?`);
    ModalsModule.updateParams(this.modalParams.delete);
    ModalsModule.openModalByType('warning');
  }

  @Action({ rawError: true })
  async deleteById(params: { id: string }) {
    try {
      const result = await deleteEmployeeFromFavorite(this.modalParams.delete.shopId as string, params.id);
      if (!result.message) {
        ResponseHandlerModule.showNotify({
          message: 'Сотрудник удалён из любимого/нелюбимого списка',
          type: 'ok',
        });
        await this.initListById(this.modalParams.delete.shopId as string);
      } else {
        ResponseHandlerModule.showNotify({ message: result.message, type: 'fail' });
      }
    } catch (error) {
      if (error.response.data.errors?.fields) {
        ResponseHandlerModule.showNotify({ message: error.response.data.errors.fields, type: 'fail' });
      }
    }
  }
}

export default getModule(ShopsEmployeesModule);
