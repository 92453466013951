
import { Watch, Component } from '@/lib/decorator';
import { ref } from 'vue';
import Form from '@/layouts/Form';
import ModalBtnBottom from '@/layouts/ModalBtnBottom.vue';
import FormTextarea from '@/components/form/Textarea/index.vue';
import FormAutocomplete from '@/components/form/Autocomplete/index.vue';
import FormButton from '@/components/form/Button/index.vue';
import formValidate from '@/lib/formFactory/validate';
import ResponseHandlerModule from '@/store/modules/responseHandler';

import ShopsEmployeesEntityModule from '@/store/shops/employees/entity';
import { strings } from '@/lib/stringConst';
import { searchEmployee } from '@/api/employees';
import { ShopEmployeesEntityModel } from '@/interfaces/models/shop.interface';
import { EmployeeSearchItem } from '@/interfaces/shift.interface';
import { GuiFormRadio } from '@library/gigant_ui';

@Component({
  components: {
    GuiFormRadio,
    ModalBtnBottom,
    FormTextarea,
    FormAutocomplete,
    FormButton,
  },
  emits: ['closeModal'],
  beforeMount() {
    this.shopId = this.$route.params.shopId as string;
  },
})
export default class ShopEmployeesForm extends Form {
  shopId = '';
  comment = this.model.comment ?? '';
  error = '';
  form = ref(null);
  validate = formValidate(this.form);
  employee = { id: null, value: '' };
  employeesItems: { id: string; value: string }[] = [];
  employeesLoading = false;

  isFavouriteItems = [
    { value: 'is_favourite', name: 'Любимый', id: 2, icon: 'smile-love' },
    { value: 'is_liked', name: 'Нравится', id: 1, icon: 'smile-like' },
    { value: 'is_hated', name: 'Нелюбимый', id: 0, icon: 'smile-hated' },
  ];

  isFavourite = this.model.isFavourite ?? {};

  get model(): ShopEmployeesEntityModel {
    return ShopsEmployeesEntityModule.model;
  }

  get text(): Record<string, string> {
    return strings;
  }

  returnBefore(): void {
    this.$emit('closeModal');
  }

  updateFavorite(value: { value: string }): void {
    ShopsEmployeesEntityModule.updateIsFavourite(value);

    this.error = '';
  }

  prepareResultSearchEmployee(employees: EmployeeSearchItem[]): { id: string; value: string; html: string }[] {
    const shareIcon = '<span class="icon-share cp-text__text-primary" title="Общий исполнитель"></span>';
    const testBadge = '<span class="cp-badge cp-badge_test cp-margin__l--3">Test</span>';

    const addBadge = (badge: string, condition: boolean) => (condition ? badge : '');
    const prepareTitle = (item: EmployeeSearchItem): string => {
      const title = [
        item.fullNameWithBirthday,
        addBadge(shareIcon, item.sharing !== 'none'),
        addBadge(testBadge, item.isTest),
      ]
        .filter(Boolean)
        .join(' ');

      return `<span style="gap: 5px" class="cp-flex cp-flex--align-center">${title}</span>`;
    };

    return employees.map((item) => ({
      id: item.id.toString(),
      value: item.fullNameWithBirthday,
      html: prepareTitle(item),
    }));
  }

  async searchEmployee(value: string): Promise<void> {
    if (value.length > 2) {
      try {
        this.employeesLoading = true;

        const result = await searchEmployee(value);

        this.employeesItems = this.prepareResultSearchEmployee(result);

        this.employeesLoading = false;
      } catch (error) {
        this.employeesItems = [];
      }
    } else {
      this.employeesItems = [];
    }
  }

  selectEmployee(employee: { id: string; value: string; data: { fullName: string } }): void {
    ShopsEmployeesEntityModule.updateEmployee({ id: employee.id, value: employee.data.fullName });
  }

  updateComment(value: string): void {
    ShopsEmployeesEntityModule.updateComment(value);
  }

  async save(): Promise<void> {
    this.error = '';
    const valid = await this.validate.validate();

    if (!valid) {
      return;
    }

    if (typeof this.isFavourite.value === 'undefined') {
      ResponseHandlerModule.showNotify({
        message: 'Не выбран статус Исполнителя: "Любимый", "Нравится" или "Нелюбимый"',
        type: 'fail',
      });

      return;
    }

    if (this.isFavourite.value === 'is_hated' && !this.comment.length) {
      this.error = 'Для статуса "Нелюбимый" необходимо оставить комментарий';

      ResponseHandlerModule.showNotify({ message: this.error, type: 'fail' });

      return;
    }

    if (this.isFavourite.value === 'is_hated') {
      ShopsEmployeesEntityModule.setHatedWarning(this.shopId);

      return;
    }

    await ShopsEmployeesEntityModule.saveUpdate(this.shopId);
    this.returnBefore();
  }

  @Watch('model.comment')
  onChangeModelComment(value: string): void {
    this.comment = value;
  }

  @Watch('model.isFavourite')
  onChangeModelIsFavourite(value: any): void {
    this.isFavourite = value;
  }
}
