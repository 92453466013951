
import { ref, toRefs, defineComponent, PropType } from 'vue';

import uiAutocompleteApi from '@/lib/formFactory/autocomplete/ui';
import handlerAutocompleteApi from '@/lib/formFactory/autocomplete/handler';
import listAutocompleteApi from '@/lib/formFactory/autocomplete/list';
import clickOutsideAutocompleteApi from '@/lib/formFactory/autocomplete/clickOutside';
import validateAutocompleteApi from '@/lib/formFactory/autocomplete/validate';

import nameTextFieldApi from '@/lib/formFactory/textField/name';
import rulesTextFieldApi from '@/lib/formFactory/textField/rules';
import errorTextFieldApi from '@/lib/formFactory/textField/error';
import typesTextFieldApi from '@/lib/formFactory/textField/types';
import masksTextFieldApi from '@/lib/formFactory/textField/masks';
import { SelectItem } from '@/lib/formFactory/select.interface';

import { ParamsValidateMessages } from '@/lib/formFactory/textField/validate';

import Loading from '@/components/Loading.vue';
import IconFont from '@/components/icons/IconFont.vue';
import { Ref } from '@vue/reactivity';

interface Item {
  id?: number;
  value?: string;
  html?: string;
  linkHtml?: string;
}

export default defineComponent({
  name: 'form-autocomplete',
  emits: ['update:modelValue', 'update:isError', 'update:modifyValue', 'update', 'validate', 'search', 'select'],
  props: {
    modelValue: { type: [String, Object] },
    autofocus: { type: Boolean },
    additional: { type: String },
    disabled: { type: Boolean },
    placeholder: { type: String },
    label: { type: String },
    readonly: { type: Boolean },
    name: { type: String },
    dataName: { type: String },
    id: { type: String },
    hint: { type: String },
    labelActive: { type: Boolean },
    validateOff: { type: Boolean },
    validateMessages: { type: Object as PropType<ParamsValidateMessages> },
    required: { type: Boolean },
    error: { default: '', type: [String, Boolean] },
    errorMessages: { default: '', type: [String, Array] },
    rules: { type: Function as PropType<(attr: unknown) => boolean | string> },
    icon: { type: String },
    iconSize: { type: String },
    items: { type: Array as PropType<Item[] | SelectItem[]>, required: true },
    keyValue: { type: String },
    keyId: { type: String },
    loading: { type: Boolean },
    type: { type: String, default: 'text' },
    mask: { type: String },
    maskConfig: { type: Object },
    autoMaskOff: { type: Boolean },
    hideEmptyValue: { type: Boolean },
    noChoise: { type: Boolean },
    hideList: { type: Boolean },
    clearSelection: { type: Boolean },
    reactModel: { type: Boolean },
    isTextArea: { type: Boolean, default: false },
    minLength: { type: Number, default: 1 },
  },

  watch: {
    // TODO: подумать о лучшем решении (textarea не растягивается под контент автоматом)
    'handler.value.value': function () {
      if (this.isTextArea) {
        setTimeout(() => {
          //@ts-ignore
          this.$refs.root.style.height = 'auto';
          //@ts-ignore
          this.$refs.root.style.height = this.$refs.root.scrollHeight + 'px';
        });
      }
    },
  },
  setup(props, { emit }) {
    const propsRefs = toRefs(props);
    const modelValue = propsRefs.modelValue as Ref<{ id: string | number; value: string | number }>;

    const propError = propsRefs.error;
    const propErrorMessages = propsRefs.errorMessages;

    const root = ref(null);

    const ui = uiAutocompleteApi({
      modelValue: modelValue,
      emit: emit as (eventName: string, eventData: unknown) => void,
    });

    const handler = handlerAutocompleteApi({
      input: emit as (eventName: string, eventData: unknown) => void,
      value: props.modelValue as string | number | Ref<string | number>,
      noChoise: props.noChoise,
      minLength: props.minLength,
      ui,
    });

    const clickOutside = clickOutsideAutocompleteApi({
      emit: emit as (eventName: string, eventData: unknown) => void,
      ref: root,
      ui,
      handler,
      modelValue,
      reactModel: props.reactModel,
    });

    const nameResult = nameTextFieldApi({
      dataName: propsRefs.dataName,
      label: propsRefs.label,
    });

    const types = typesTextFieldApi({ type: props.type });

    const list = listAutocompleteApi({
      input: emit as (eventName: string, eventData: unknown) => void,
      keyValue: props.keyValue,
      keyId: props.keyId,
      handler,
      ui,
      clearSelection: props.clearSelection,
    });

    const masks = masksTextFieldApi({
      el: root,
      type: props.type,
      autoMaskOff: props.autoMaskOff,
      mask: props.mask,
      maskConfig: props.maskConfig,
    });

    const validate = validateAutocompleteApi({
      value: modelValue,
      validateOff: props.validateOff,
      required: props.required,
      validateMessages: props.validateMessages,
    });

    const rulesResult = rulesTextFieldApi({
      value: handler.value,
      rules: props.rules,
    });

    const errorResult = errorTextFieldApi({
      emit: emit as (eventName: string, eventData: unknown) => void,
      error: propError,
      errorMessages: propErrorMessages,
      validate: validate.validate,
      validateMessage: validate.validateMessage,
      rules: rulesResult.validate,
      rulesMessage: rulesResult.validateMessage,
    });

    return {
      value: modelValue,
      propError,
      propErrorMessages,
      root,
      ui,
      handler,
      list,
      nameResult,
      validate,
      rulesResult,
      errorResult,
      clickOutside,
      types,
      masks,
    };
  },
  components: {
    IconFont,
    Loading,
  },
});
