
import { Component, Vue } from '@/lib/decorator';
import ModalBlock from '@/components/Modal.vue';
import FormBlock from './_form.vue';
import FormEditBlock from './_formEdit.vue';
import AppHeaderBtnAdd from '@/layouts/partials/AppHeaderBtnAdd.vue';
import TitleTab from '../_titleTab.vue';

import UserModule from '@/store/user';
import ShopEntityModule from '@/store/shops/entity';
import ShopsEmployeesModule from '@/store/shops/employees/index';
import ShopsEmployeesEntityModule from '@/store/shops/employees/entity';
import AppTable from '@/components/ui/table/Table.vue';
import IconFont from '@/components/icons/IconFont.vue';
import FavoriteItem from '@/components/table-items/FavoriteItem.vue';
import AppRow from '@/components/ui/grid/flex/Row.vue';
import AppCol from '@/components/ui/grid/flex/Col.vue';
import { PageInterface } from '@/lib/layouts/page/page.interface';
import { ModalParamsInterface } from '@/store/shops/employees/modalParams';
import { GuiLoader } from '@library/gigant_ui';

@Component({
  components: {
    GuiLoader,
    AppCol,
    AppRow,
    FavoriteItem,
    IconFont,
    AppTable,
    ModalBlock,
    FormBlock,
    FormEditBlock,
    AppHeaderBtnAdd,
    TitleTab,
  },
  beforeMount(): void {
    this.shopId = this.$route.params.shopId as string;
  },
  mounted(): void {
    ShopEntityModule.initTitle(this.shopId);
    ShopsEmployeesModule.initListById(this.shopId);
  },
})
export default class ShopEmployees extends Vue {
  shopId = '';

  get currentTab(): string {
    return ShopEntityModule.tabsNav.employees?.id;
  }

  get settings(): PageInterface {
    return ShopsEmployeesModule.pageSettings;
  }

  get modalParams(): ModalParamsInterface {
    return ShopsEmployeesModule.modalParams;
  }

  get actionsHandler(): {} | undefined {
    return ShopsEmployeesModule.pageSettings.actionsHandler;
  }

  checkCanViewProfile(partnerUuid: string): boolean {
    return UserModule.isSupervisor || UserModule.partner?.uuid === partnerUuid;
  }

  toAdd(): void {
    ShopsEmployeesModule.showModal('add');
  }

  closeModal(): void {
    ShopsEmployeesModule.hideModal('add');
    ShopsEmployeesModule.hideModal('edit');
  }

  deleteRow(id: string): void {
    ShopsEmployeesModule.deleteFromFavorite({
      shopId: this.shopId,
      employeeId: id,
    });
  }

  openStatus(id: number): void {
    this.toEdit(id.toString());
  }

  sort(header: { id: string; sort: { value: string } }): void {
    ShopsEmployeesModule.sort({ field: header.id, sort: header.sort.value || '' });
  }

  selectAmount(value: string): void {
    ShopsEmployeesModule.updatePageAmountItems(value);
  }

  toEdit(id: string): void {
    ShopsEmployeesEntityModule.editFavorite({ shopId: this.shopId, employeeId: id });
  }
}
