import { FormInputUIResult } from '@/lib/formFactory/autocomplete/ui';
import { FormAutocompleteHandlerResult } from '@/lib/formFactory/autocomplete/handler';

export interface FormAutocompleteListParams {
  input: (eventName: string, eventData: unknown) => void;
  keyValue?: string;
  keyId?: string;
  handler: FormAutocompleteHandlerResult;
  ui: FormInputUIResult;
  clearSelection: boolean;
}

export interface FormAutocompleteListResult {
  select: (item: Record<string, unknown>) => void;
}

export default function formAutocompleteList(params: FormAutocompleteListParams): FormAutocompleteListResult {
  const select = (item: Record<string, unknown>) => {
    const result = {
      id: params.keyId ? item[params.keyId] : item.id,
      value: params.keyValue ? item[params.keyValue] : item.value,
      data: item,
    } as { id: string | number; value: string | number; data: unknown };

    params.input('update:modelValue', result);
    params.input('select', result);

    params.handler.value.value = result.value;

    if (params.clearSelection) {
      const clear = { id: null, value: '' };
      params.input('update:modelValue', clear);
      params.input('select', clear);
      params.handler.value.value = '';
    }

    params.ui.setViewList(false);
  };

  return {
    select,
  };
}
