import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "cp-modal-form__wrapper" }
const _hoisted_2 = { class: "mr-editable-item-list__wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_editable_item = _resolveComponent("editable-item")!
  const _component_gui_form_radio = _resolveComponent("gui-form-radio")!
  const _component_form_textarea = _resolveComponent("form-textarea")!
  const _component_form_button = _resolveComponent("form-button")!
  const _component_modal_btn_bottom = _resolveComponent("modal-btn-bottom")!

  return (_openBlock(), _createElementBlock("form", {
    ref: "form",
    onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.save && _ctx.save(...args)), ["prevent"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _renderSlot(_ctx.$slots, "default", {}, () => [
          _createVNode(_component_editable_item, {
            data: { name: _ctx.model.employee.value }
          }, null, 8, ["data"])
        ])
      ]),
      _createVNode(_component_gui_form_radio, {
        modelValue: _ctx.isFavourite,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isFavourite) = $event)),
        items: _ctx.isFavouriteItems,
        icons: "",
        "icon-size": "22",
        class: "cp-margin__t--small",
        onUpdate: _ctx.updateFavorite
      }, null, 8, ["modelValue", "items", "onUpdate"]),
      _createVNode(_component_form_textarea, {
        modelValue: _ctx.comment,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.comment) = $event)),
        label: "Комментарий",
        rows: 1,
        "max-length": "255",
        class: "cp-textarea__line",
        "error-messages": _ctx.error,
        onUpdate: _ctx.updateComment
      }, null, 8, ["modelValue", "error-messages", "onUpdate"])
    ]),
    _createVNode(_component_modal_btn_bottom, { position: "end" }, {
      default: _withCtx(() => [
        _createVNode(_component_form_button, {
          text: _ctx.text.btnSave,
          outline: true,
          type: 'submit'
        }, null, 8, ["text"])
      ]),
      _: 1
    })
  ], 544))
}