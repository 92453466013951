import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "cp-modal-form__wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_form_autocomplete = _resolveComponent("form-autocomplete")!
  const _component_gui_form_radio = _resolveComponent("gui-form-radio")!
  const _component_form_textarea = _resolveComponent("form-textarea")!
  const _component_form_button = _resolveComponent("form-button")!
  const _component_modal_btn_bottom = _resolveComponent("modal-btn-bottom")!

  return (_openBlock(), _createElementBlock("form", {
    ref: "form",
    onSubmit: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.save && _ctx.save(...args)), ["prevent"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_form_autocomplete, {
        modelValue: _ctx.employee,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.employee) = $event)),
        placeholder: "Начните вводить имя или номер телефона",
        icon: "user-search",
        "icon-size": "18",
        required: "",
        class: "cp-margin__b--small",
        items: _ctx.employeesItems,
        loading: _ctx.employeesLoading,
        onSearch: _ctx.searchEmployee,
        onSelect: _ctx.selectEmployee
      }, null, 8, ["modelValue", "items", "loading", "onSearch", "onSelect"]),
      _createVNode(_component_gui_form_radio, {
        modelValue: _ctx.isFavourite,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isFavourite) = $event)),
        items: _ctx.isFavouriteItems,
        icons: "",
        "icon-size": "22",
        class: "cp-margin__t--small",
        onUpdate: _ctx.updateFavorite
      }, null, 8, ["modelValue", "items", "onUpdate"]),
      _createVNode(_component_form_textarea, {
        modelValue: _ctx.comment,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.comment) = $event)),
        label: "Комментарий",
        rows: 1,
        "max-length": "255",
        class: "cp-textarea__line",
        "error-messages": _ctx.error,
        onUpdate: _ctx.updateComment
      }, null, 8, ["modelValue", "error-messages", "onUpdate"])
    ]),
    _createVNode(_component_modal_btn_bottom, { position: "end" }, {
      default: _withCtx(() => [
        _createVNode(_component_form_button, {
          text: _ctx.text.btnSave,
          type: 'submit',
          outline: true
        }, null, 8, ["text"])
      ]),
      _: 1
    })
  ], 544))
}