
import { defineComponent } from 'vue';

const component = defineComponent({
  name: 'IconAddReturn',
  props: {
    color: {
      type: String,
    },
  },
});

export default component;
