import { ref, onMounted } from 'vue';
import { FormInputUIResult } from '@/lib/formFactory/autocomplete/ui';
import { Ref } from '@vue/reactivity/dist/reactivity';

interface EventTargetValue {
  target: {
    value: string;
  };
}

export interface FormAutocompleteHandlerParams {
  input: (eventName: string, eventData: unknown) => void;
  value: string | number | Ref<string | number>;
  noChoise: boolean;
  minLength: number;
  ui: FormInputUIResult;
}

export interface FormAutocompleteHandlerResult {
  update: (params: EventTargetValue) => void;
  value: Ref<string | number>;
  paste: (event: ClipboardEvent) => void;
}

export default function formAutocompleteHandler(params: FormAutocompleteHandlerParams): FormAutocompleteHandlerResult {
  const { input, value, noChoise, minLength, ui } = params;
  const modelValue = ref(value);

  const update = ({ target: { value } }: EventTargetValue) => {
    ui.setViewList(true);

    input('update', value);

    if (value.trim().length >= minLength) {
      input('search', value.trim());
    }

    if (noChoise) {
      input('update:modelValue', {
        id: null,
        value,
      });
    }

    modelValue.value = value;
  };

  const paste = (event: ClipboardEvent) => {
    if (event.clipboardData) {
      const value = event.clipboardData.getData('text');

      setTimeout(() => {
        input('update', value);

        if (value.trim().length >= minLength) {
          input('search', value.trim());
        }

        if (noChoise) {
          input('update:modelValue', {
            id: null,
            value,
          });
        }

        modelValue.value = value;
      });
    }
  };

  const init = () => {
    if (typeof modelValue.value === 'object') {
      modelValue.value = (modelValue.value as Ref<string | number>).value;

      return;
    }

    modelValue.value = '';
  };

  onMounted(init);

  return {
    update,
    value: modelValue,
    paste,
  };
}
