
import { Watch, Component } from '@/lib/decorator';
import { ref } from 'vue';
import Form from '@/layouts/Form';
import EditableItem from '@/components/form/EditableItem.vue';
import ModalBtnBottom from '@/layouts/ModalBtnBottom.vue';
import FormTextarea from '@/components/form/Textarea/index.vue';
import FormButton from '@/components/form/Button/index.vue';
import formValidate from '@/lib/formFactory/validate';
import ResponseHandlerModule from '@/store/modules/responseHandler';

import ShopsEmployeesEntityModule from '@/store/shops/employees/entity';
import { strings } from '@/lib/stringConst';
import { ShopEmployeesEntityModel } from '@/interfaces/models/shop.interface';
import { GuiFormRadio } from '@library/gigant_ui';

@Component({
  components: {
    GuiFormRadio,
    EditableItem,
    ModalBtnBottom,
    FormTextarea,
    FormButton,
  },
  emits: ['closeModal'],
  beforeMount() {
    this.shopId = this.$route.params.shopId as string;
  },
})
export default class ShopEmployeesFormEdit extends Form {
  shopId = '';
  comment = this.model.comment ?? '';
  error = '';
  form = ref(null);
  validate = formValidate(this.form);

  isFavouriteItems = [
    { value: 'is_favourite', name: 'Любимый', id: 2, icon: 'smile-love' },
    { value: 'is_liked', name: 'Нравится', id: 1, icon: 'smile-like' },
    { value: 'is_hated', name: 'Нелюбимый', id: 0, icon: 'smile-hated' },
  ];
  isFavourite = this.model.isFavourite ?? {};

  get model(): ShopEmployeesEntityModel {
    return ShopsEmployeesEntityModule.model;
  }

  get text(): Record<string, string> {
    return strings;
  }

  returnBefore(): void {
    this.$emit('closeModal');
  }

  updateFavorite(value: { value: string }): void {
    ShopsEmployeesEntityModule.updateIsFavourite(value);

    this.error = '';
  }

  updateComment(value: string): void {
    ShopsEmployeesEntityModule.updateComment(value);
  }

  async save(): Promise<void> {
    if (this.isFavourite.value === 'is_hated' && !this.comment.length) {
      this.error = 'Для статуса "Нелюбимый" необходимо оставить комментарий';

      ResponseHandlerModule.showNotify({ message: this.error, type: 'fail' });

      return;
    }

    if (this.isFavourite.value === 'is_hated') {
      ShopsEmployeesEntityModule.setHatedWarning(this.shopId);

      return;
    }

    ShopsEmployeesEntityModule.saveUpdate(this.shopId).then(() => {
      this.returnBefore();
    });
  }

  @Watch('model.comment')
  onChangeModelComment(value: string): void {
    this.comment = value;
  }

  @Watch('model.isFavourite')
  onChangeModelIsFavourite(value: {}): void {
    this.isFavourite = value;
  }
}
