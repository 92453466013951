export interface ModalParamsInterface {
  [index: string]: Record<string, string | boolean | null | Record<string, string>>;
}

export const modalParams: ModalParamsInterface = {
  add: {
    isShow: false,
    title: 'Отметить исполнителя',
  },
  edit: {
    isShow: false,
    title: 'Редактировать исполнителя',
  },
  delete: {
    id: null,
    shopId: null,
    name: '',
    text: 'Ты действительно хочешь удалить ${name} из любимого/нелюбимого?',
    handlers: {
      yes: 'shopEmplyees/deleteById',
      no: '',
    },
  },
  hated: {
    text: 'Все будущие смены этого исполнителя в объекте удалятся. Вы точно хотите этого?',
    buttonsText: {
      yes: 'Да',
      no: 'Нет',
    },
    handlers: {
      yes: 'shopEmplyees/saveUpdate',
      no: '',
    },
  },
};
